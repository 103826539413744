import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Serverless GraphQL API",
  "description": "Learn why GraphQL, how GraphQL, should it replace REST, how to choose, and implement it with Serverless",
  "image": "./img/serverless-graphql.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "serverless-graphql-api"
    }}>{`Serverless GraphQL API`}</h1>
    <p><img parentName="p" {...{
        "src": "/8904aed44a33deeb7e6ecc3cbaa7f08c/serverless-graphql.svg",
        "alt": null
      }}></img></p>
    <p>{`Say you're building an app. It needs data from a server. What do you do?`}</p>
    <p>{`You make a `}<inlineCode parentName="p">{`fetch()`}</inlineCode>{` request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`fetch("https://swapi.dev/api/people/1/")
  .then((res) => res.json())
  .then(console.log)
`}</code></pre>
    <p>{`And you get eeeevery piece of info about Luke Skywalker.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "Luke Skywalker",
  "height": "172",
  "mass": "77",
  "hair_color": "blond",
  "skin_color": "fair",
  "eye_color": "blue",
  "birth_year": "19BBY",
  "gender": "male",
  "homeworld": "https://swapi.dev/api/planets/1/",
  "films": [
    "https://swapi.dev/api/films/2/",
    "https://swapi.dev/api/films/6/",
    "https://swapi.dev/api/films/3/",
    "https://swapi.dev/api/films/1/",
    "https://swapi.dev/api/films/7/"
  ],
  "species": ["https://swapi.dev/api/species/1/"],
  "vehicles": [
    "https://swapi.dev/api/vehicles/14/",
    "https://swapi.dev/api/vehicles/30/"
  ],
  "starships": [
    "https://swapi.dev/api/starships/12/",
    "https://swapi.dev/api/starships/22/"
  ],
  "created": "2014-12-09T13:50:51.644000Z",
  "edited": "2014-12-20T21:17:56.891000Z",
  "url": "https://swapi.dev/api/people/1/"
}
`}</code></pre>
    <p>{`Frustrating ... all you wanted was his name and hair color. Why's the API sending you all this crap? 🤦‍♂️`}</p>
    <p>{`And what's this about Luke's species being `}<inlineCode parentName="p">{`1`}</inlineCode>{`? What the heck is `}<inlineCode parentName="p">{`1`}</inlineCode>{`?`}</p>
    <p>{`You make another fetch request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`fetch("https://swapi.dev/api/species/1/")
  .then((res) => res.json())
  .then(console.log)
`}</code></pre>
    <p>{`You get data about humans. Great.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "Human",
  "classification": "mammal",
  "designation": "sentient",
  "average_height": "180",
  "skin_colors": "caucasian, black, asian, hispanic",
  "hair_colors": "blonde, brown, black, red",
  "eye_colors": "brown, blue, green, hazel, grey, amber",
  "average_lifespan": "120",
  "homeworld": "https://swapi.dev/api/planets/9/",
  "language": "Galactic Basic",
  "people": [
    "https://swapi.dev/api/people/1/",
    "https://swapi.dev/api/people/4/",
    "https://swapi.dev/api/people/5/",
    "https://swapi.dev/api/people/6/",
    "https://swapi.dev/api/people/7/",
    "https://swapi.dev/api/people/9/",
    "https://swapi.dev/api/people/10/",
    "https://swapi.dev/api/people/11/",
    "https://swapi.dev/api/people/12/",
    "https://swapi.dev/api/people/14/",
    "https://swapi.dev/api/people/18/",
    "https://swapi.dev/api/people/19/",
    "https://swapi.dev/api/people/21/",
    "https://swapi.dev/api/people/22/",
    "https://swapi.dev/api/people/25/",
    "https://swapi.dev/api/people/26/",
    "https://swapi.dev/api/people/28/",
    "https://swapi.dev/api/people/29/",
    "https://swapi.dev/api/people/32/",
    "https://swapi.dev/api/people/34/",
    "https://swapi.dev/api/people/43/",
    "https://swapi.dev/api/people/51/",
    "https://swapi.dev/api/people/60/",
    "https://swapi.dev/api/people/61/",
    "https://swapi.dev/api/people/62/",
    "https://swapi.dev/api/people/66/",
    "https://swapi.dev/api/people/67/",
    "https://swapi.dev/api/people/68/",
    "https://swapi.dev/api/people/69/",
    "https://swapi.dev/api/people/74/",
    "https://swapi.dev/api/people/81/",
    "https://swapi.dev/api/people/84/",
    "https://swapi.dev/api/people/85/",
    "https://swapi.dev/api/people/86/",
    "https://swapi.dev/api/people/35/"
  ],
  "films": [
    "https://swapi.dev/api/films/2/",
    "https://swapi.dev/api/films/7/",
    "https://swapi.dev/api/films/5/",
    "https://swapi.dev/api/films/4/",
    "https://swapi.dev/api/films/6/",
    "https://swapi.dev/api/films/3/",
    "https://swapi.dev/api/films/1/"
  ],
  "created": "2014-12-10T13:52:11.567000Z",
  "edited": "2015-04-17T06:59:55.850671Z",
  "url": "https://swapi.dev/api/species/1/"
}
`}</code></pre>
    <p>{`That's a lot of JSON to get the word `}<inlineCode parentName="p">{`"Human"`}</inlineCode>{` out of the `}<a parentName="p" {...{
        "href": "https://swapi.dev/"
      }}>{`Star Wars API`}</a>{` ...`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media4.giphy.com/media/3oAt2dA6LxMkRrGc0g/giphy-loop.mp4?cid=4ac046a2kmwqj3gm5000evyskf78x8mnjbsd3a7dzw943uez&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`What about Luke's starships? There's 2 and that means 2 more API requests ...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`fetch("https://swapi.dev/api/starships/12/")
  .then((res) => res.json())
  .then(console.log)

fetch("https://swapi.dev/api/starships/22/")
  .then((res) => res.json())
  .then(console.log)
`}</code></pre>
    <p>{`Wanna know how much JSON that dumps? Try a guess. 🙄`}</p>
    <p>{`You made `}<strong parentName="p">{`4 API requests`}</strong>{` and transferred a bunch of data to find out that Luke Skywalker is human, has blond hair, and flies an X-Wing and an Imperial Shuttle.`}</p>
    <p>{`And guess what, you didn't cache anything. How often do you think this data changes? Once a year? Twice?`}</p>
    <p>{`🤦‍♂️`}</p>
    <h2 {...{
      "id": "graphql-to-the-rescue"
    }}>{`GraphQL to the rescue`}</h2>
    <p>{`Here's what the same process looks like with GraphQL.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query luke {
  // id found through allPeople query
  person(id: "cGVvcGxlOjE=") {
    name
    hairColor
    species {
      name
    }
    starshipConnection {
      starships {
        name
      }
    }
  }
}
`}</code></pre>
    <p>{`And the API returns what you wanted with 1 request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "person": {
      "name": "Luke Skywalker",
      "hairColor": "blond",
      "species": null,
      "starshipConnection": {
        "starships": [
          {
            "name": "X-wing"
          },
          {
            "name": "Imperial shuttle"
          }
        ]
      }
    }
  }
}
`}</code></pre>
    <p>{`An API mechanism that gives you flexibility on the frontend, slashes API requests, `}<em parentName="p">{`and doesn't transfer data you don't need?`}</em></p>
    <p>{`😲`}</p>
    <p>{`Write a query, say what you want, send to an endpoint, GraphQL figures out the rest. Want different params? Just say so. Want multiple models? Got it. Wanna go deep? You can.`}</p>
    <p>{`Without changes on the server. Within reason.`}</p>
    <p>{`GraphQL client libraries can add caching to avoid duplicate requests. And because queries are structured, clients can merge queries into 1 API call.`}</p>
    <p>{`I fell in love the moment it clicked.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/7FyMQm2vBiTjG/giphy.gif",
        "alt": null
      }}></img></p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "http://graphql.org/swapi-graphql"
      }}>{`try it on graphql.org's public playground`}</a></p>
    <div id="lock" />
    <h2 {...{
      "id": "what-is-graphql"
    }}>{`What `}<em parentName="h2">{`is`}</em>{` GraphQL`}</h2>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/GraphQL"
      }}>{`GraphQL`}</a>{` is an open-source data query and manipulation language for APIs and a runtime for fulfilling queries with existing data.`}</p>
    <p>{`Touted as a replacement for REST, GraphQL is actually a different approach to APIs. Better for common use-cases and with its own drawbacks.`}</p>
    <p>{`Don't let the propaganda fool you 👉 you can and `}<em parentName="p">{`should`}</em>{` use both REST `}<em parentName="p">{`and`}</em>{` GraphQL. Depends on what you're doing.`}</p>
    <p>{`GraphQL's benefit is its declarative nature.`}</p>
    <p>{`On the client, you describe the shape of what you want and GraphQL figures it out. On the server, you write resolver functions for sub-queries and GraphQL combines them into the full result.`}</p>
    <p>{`REST queries can be declarative on the client, while GraphQL is declarative on both ends. For reads `}<em parentName="p">{`and`}</em>{` writes.`}</p>
    <h3 {...{
      "id": "graphql-queries"
    }}>{`GraphQL queries`}</h3>
    <p>{`GraphQL queries fetch data. Following this pattern:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query {
  what_you_want {
    its_property
  }
}
`}</code></pre>
    <p>{`You nest fields – representing your models – and properties into queries to describe what you're looking for. You can go as deep as you want.`}</p>
    <p>{`Write fields side-by-side to execute multiple queries with a single API request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query {
  what_you_want {
    its_property
  }
  other_thing_you_want {
    its_property {
      property_of_property
    }
  }
}
`}</code></pre>
    <p>{`This is where the power and flexibility come from.`}</p>
    <p>{`Variables let you create dynamic queries and build complex filters to limit the scope of your result.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query queryName($filterProp: "value") {
    field(filterProp: $filterProp) {
        property1
        property3
    }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`$filterProp`}</inlineCode>{` defines a new variable that the `}<inlineCode parentName="p">{`field()`}</inlineCode>{` lookup uses to filter results for those matching `}<inlineCode parentName="p">{`"value"`}</inlineCode>{`.`}</p>
    <p>{`GraphQL comes with basic equality filters built-in and you're encouraged to add more in your resolvers. Typical projects choose to support sorting, greater-than, not-equals, etc.`}</p>
    <h3 {...{
      "id": "graphql-mutations"
    }}>{`GraphQL mutations`}</h3>
    <p>{`GraphQL mutations write data. Following this pattern:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  what_youre_updating(argument: "value", argument2: "value 2") {
    prop_you_want_back
  }
}
`}</code></pre>
    <p>{`Like a query with arguments, you specify what you're updating and pass your values. What's available depends on how mutation resolvers are implemented on the server.`}</p>
    <p>{`The mutation body specifies what you'd like returned. Same as a query.`}</p>
    <p>{`Like queries, you can put mutations side-by-side and use variables.`}</p>
    <pre><code parentName="pre" {...{}}>{`mutation mutationName($argument: "value") {
    what_youre_updating(argument: $argument) {
        prop_you_want_back
    }

    other_field(argument: $argument) {
        return_prop
    }
}
`}</code></pre>
    <h2 {...{
      "id": "graphql-vs-rest-which-to-use-and-when"
    }}>{`GraphQL vs. REST, which to use and when`}</h2>
    <p>{`The GraphQL vs. REST debate is a false dichotomy.`}</p>
    <p>{`People like to say GraphQL is `}<em parentName="p">{`replacing`}</em>{` REST and that's not the case. GraphQL is `}<em parentName="p">{`augmenting`}</em>{` REST.`}</p>
    <p>{`Will GraphQL become the default API layer? It might.`}</p>
    <p>{`Should you rip out your REST API and rewrite for GraphQL? Please don't.`}</p>
    <p>{`GraphQL doesn't care where data comes from. Relational database, NoSQL database, files, a REST API, another GraphQL API ... All you need is a function that reads data in response to a query.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/how-you-can-start-using-graphql-today-without-changing-the-backend/"
      }}>{`start using GraphQL without changing your existing server`}</a>{` with a GraphQL middle layer. Create a GraphQL server on top of your REST API.`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media3.giphy.com/media/26ufdipQqU2lhNA4g/giphy-loop.mp4?cid=4ac046a25rkmivfxmn02fkyo3h6lqdoi9aphgo9blabqqfti&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <h3 {...{
      "id": "how-do-you-choose-between-rest-and-graphql"
    }}>{`How do you choose between REST and GraphQL?`}</h3>
    <p>{`When building a typical CRUD – Create, Read, Update, Delete – API, I like to ask 6 questions:`}</p>
    <ol>
      <li parentName="ol">{`Is this a new project? `}<em parentName="li">{`Default to GraphQL`}</em></li>
      <li parentName="ol">{`Do I know in advance what clients are going to need? `}<em parentName="li">{`REST is great`}</em></li>
      <li parentName="ol">{`Am I fetching small subsets of large data? `}<em parentName="li">{`GraphQL`}</em></li>
      <li parentName="ol">{`Am I generating new values for each request? `}<em parentName="li">{`REST`}</em></li>
      <li parentName="ol">{`Updating a few properties on an object? `}<em parentName="li">{`GraphQL`}</em></li>
      <li parentName="ol">{`Submitting large payloads to be saved? `}<em parentName="li">{`REST`}</em></li>
    </ol>
    <p>{`Use upserts to deal with Create and Update. Avoid deletes.`}</p>
    <h3 {...{
      "id": "should-you-expose-your-entire-data-model-verbatim"
    }}>{`Should you expose your entire data model verbatim?`}</h3>
    <p>{`No.`}</p>
    <p>{`Exposing your full data model is a common mistake in API design. `}</p>
    <p>{`Define a clean API using `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Domain-driven_design"
      }}>{`domain driven design`}</a>{`. How your backend stores data for max performance and good database design differs from how clients think about that data.`}</p>
    <p>{`An "access to everything" API style is hard to use, leads to security issues, is difficult to maintain, and tightly couples servers and clients. You'll have to update both server and client for any DB change. `}</p>
    <p>{`Then there's the question of derived and computed properties with complex business logic. You don't want to re-implement those on the client using raw data from your database.`}</p>
    <h2 {...{
      "id": "how-to-create-a-serverless-graphql-server"
    }}>{`How to create a serverless GraphQL server`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/15128e32e054e8af8408045061f1901d/9d6a9/graphql-playground.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "60.0896860986547%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'241\\'%20viewBox=\\'0%200%20400%20241\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%20121v120h401V0H0v121m195-75c-6%204-6%2011-1%2016%207%207%2019%201%2018-9-2-7-11-11-17-7m197%2042v11h8V76h-8v12m1%2016l-1%2014v14h8v-29h-3l-4%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/15128e32e054e8af8408045061f1901d/ca0a1/graphql-playground.webp 223w", "/static/15128e32e054e8af8408045061f1901d/75680/graphql-playground.webp 445w", "/static/15128e32e054e8af8408045061f1901d/8d1ba/graphql-playground.webp 890w", "/static/15128e32e054e8af8408045061f1901d/3838e/graphql-playground.webp 1335w", "/static/15128e32e054e8af8408045061f1901d/e11e5/graphql-playground.webp 1780w", "/static/15128e32e054e8af8408045061f1901d/328c2/graphql-playground.webp 2256w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/15128e32e054e8af8408045061f1901d/e92b6/graphql-playground.png 223w", "/static/15128e32e054e8af8408045061f1901d/e66bf/graphql-playground.png 445w", "/static/15128e32e054e8af8408045061f1901d/4ef49/graphql-playground.png 890w", "/static/15128e32e054e8af8408045061f1901d/4e814/graphql-playground.png 1335w", "/static/15128e32e054e8af8408045061f1901d/701e9/graphql-playground.png 1780w", "/static/15128e32e054e8af8408045061f1901d/9d6a9/graphql-playground.png 2256w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/15128e32e054e8af8408045061f1901d/4ef49/graphql-playground.png",
                "alt": "Example create mutation from server below",
                "title": "Example create mutation from server below",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Example create mutation from server below`}</figcaption>{`
  `}</figure></p>
    <p>{`There's many ways to build a GraphQL server, the nicest I've found is with `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/"
      }}>{`Apollo`}</a>{`'s `}<a parentName="p" {...{
        "href": "https://github.com/apollographql/apollo-server/tree/master/packages/apollo-server-lambda"
      }}>{`apollo-server-lambda`}</a>{` package.`}</p>
    <p>{`You follow a 4 step process:`}</p>
    <ol>
      <li parentName="ol">{`Add lambda to your `}<inlineCode parentName="li">{`serverless.yml`}</inlineCode>{` file`}</li>
      <li parentName="ol">{`Initialize the Apollo server`}</li>
      <li parentName="ol">{`Specify your schema`}</li>
      <li parentName="ol">{`Add resolvers`}</li>
    </ol>
    <p>{`We created a `}<a parentName="p" {...{
        "href": "https://serverlesshandbook.dev/serverless-rest-api#build-a-simple-rest"
      }}>{`small REST API`}</a>{` in the previous chapter, let's recreate it with GraphQL. `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverlesshandbook.dev/tree/master/examples/serverless-graphql-example"
      }}>{`Full code on GitHub`}</a></p>
    <p>{`Apollo creates a GraphQL playground for us. You can try my implementation here:`}</p>
    <iframe src="https://yrqqg5l31m.execute-api.us-east-1.amazonaws.com/dev/graphql" style={{
      width: "120%",
      border: 0,
      height: "600px"
    }}></iframe>
    <p>{`Change the URL inside the playground to: `}<inlineCode parentName="p">{`https://yrqqg5l31m.execute-api.us-east-1.amazonaws.com/dev/graphql`}</inlineCode>{`. Apollo drops the `}<inlineCode parentName="p">{`/dev/`}</inlineCode>{` part.`}</p>
    <h3 {...{
      "id": "serverlessyml"
    }}>{`serverless.yml`}</h3>
    <p>{`We define a new function in the `}<inlineCode parentName="p">{`functions:`}</inlineCode>{` section.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

functions:
  graphql:
    handler: dist/graphql.handler
    events:
      - http:
          path: graphql
          method: GET
          cors: true
      - http:
          path: graphql
          method: POST
          cors: true
`}</code></pre>
    <p>{`The convention is to use the `}<inlineCode parentName="p">{`/graphql`}</inlineCode>{` endpoint for everything. `}</p>
    <p>{`Make sure to define both `}<inlineCode parentName="p">{`GET`}</inlineCode>{` and `}<inlineCode parentName="p">{`POST`}</inlineCode>{` endpoints. `}<inlineCode parentName="p">{`GET`}</inlineCode>{` serves the Apollo playground, `}<inlineCode parentName="p">{`POST`}</inlineCode>{` handles the queries and mutations.`}</p>
    <h3 {...{
      "id": "initialize-apollo-server"
    }}>{`initialize Apollo server`}</h3>
    <p>{`Every Apollo server needs a schema, a resolvers object, and the initialized server. Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/graphql.ts

import { ApolloServer, gql } from "apollo-server-lambda"

// this is where we define the shape of our API
const schema = gql\`\`

// this is where the shape maps to functions
const resolvers = {
  Query: {},
  Mutation: {},
}

const server = new ApolloServer({ typeDefs: schema, resolvers })

export const handler = server.createHandler({
  cors: {
    origin: "*", // for security in production, lock this to your real URLs
    credentials: true,
  },
})
`}</code></pre>
    <p>{`This server won't run because the `}<inlineCode parentName="p">{`schema`}</inlineCode>{` and `}<inlineCode parentName="p">{`resolvers`}</inlineCode>{` don't match. Resolvers have fields that the schema does not.`}</p>
    <p>{`We'll add type definitions to the schema and a resolver for each `}<inlineCode parentName="p">{`Query`}</inlineCode>{` and `}<inlineCode parentName="p">{`Mutation`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "specify-your-schema"
    }}>{`specify your schema`}</h3>
    <p>{`Your schema defines the shape of your API. Every type of object your server returns and every query and mutation definition.`}</p>
    <p>{`You get a type-safe API because GraphQL ensures objects follow this schema. Both when coming into the API and when flying out.`}</p>
    <p>{`To mimic our `}<a parentName="p" {...{
        "href": "https://serverlesshandbook.dev/serverless-rest-api#build-a-simple-rest"
      }}>{`CRUD API from before`}</a>{`, we use a schema like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/graphql.ts

const schema = gql\`
  type Item {
    id: String
    name: String
    body: String
    createdAt: String
    updatedAt: String
  }

  type Query {
    item(id: String!): Item
  }

  type Mutation {
    updateItem(id: String, name: String, body: String): Item
    deleteItem(id: String!): Item
  }
\`
`}</code></pre>
    <p>{`With REST, users could store and retrieve arbitrary JSON blobs. GraphQL doesn't support that. Instead, we define an `}<inlineCode parentName="p">{`Item`}</inlineCode>{` type with an arbitrary `}<inlineCode parentName="p">{`body`}</inlineCode>{` string.`}</p>
    <p>{`You can use that to store serialized JSON objects.`}</p>
    <p>{`Each item will have an `}<inlineCode parentName="p">{`id`}</inlineCode>{`, a `}<inlineCode parentName="p">{`name`}</inlineCode>{`, and a few timestamps managed by the server. Those help with debugging.`}</p>
    <p>{`Our `}<inlineCode parentName="p">{`item()`}</inlineCode>{` query lets you retrieve items and requires an `}<inlineCode parentName="p">{`id`}</inlineCode>{` to work. That's the exclamation point after `}<inlineCode parentName="p">{`String`}</inlineCode>{`.`}</p>
    <p>{`Then we have a mutation for upserting items and a mutation for deleting.`}</p>
    <h3 {...{
      "id": "create-your-resolvers"
    }}>{`create your resolvers`}</h3>
    <p>{`We have 1 query and 2 mutations. That means we'll need 3 resolver functions.`}</p>
    <p>{`We can copy these from the `}<a parentName="p" {...{
        "href": "https://serverlesshandbook.dev/serverless-rest-api#build-a-simple-rest"
      }}>{`REST implementation`}</a>{` and change how we get arguments.`}</p>
    <p>{`I like to put queries in a `}<inlineCode parentName="p">{`src/queries.ts`}</inlineCode>{` file and mutations in a `}<inlineCode parentName="p">{`src/mutations.ts`}</inlineCode>{`. You can organize this by model as your implementation grows.`}</p>
    <h4 {...{
      "id": "item"
    }}>{`item()`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`item()`}</inlineCode>{` query is a basic fetch and looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/queries.ts

import * as db from "simple-dynamodb"

function remapProps(item: any) {
  return {
    ...item,
    id: item.itemId,
    name: item.itemName,
  }
}

// fetch using item(id: String)
export const item = async (_: any, args: { id: string }) => {
  const item = await db.getItem({
    TableName: process.env.ITEM_TABLE!,
    Key: {
      itemId: args.id,
    },
  })

  return remapProps(item.Item)
}
`}</code></pre>
    <p>{`We can ignore Apollo's first resolver argument. The second argument is where query and mutation values come in.`}</p>
    <p>{`Since `}<inlineCode parentName="p">{`id`}</inlineCode>{` is a required param, you don't have to check for nulls. GraphQL handles that before calling your resolver.`}</p>
    <p>{`You can call `}<inlineCode parentName="p">{`getItem`}</inlineCode>{` on DynamoDB, or run a SQL query for a relational database, and return the result. GraphQL handles the rest.`}</p>
    <p><strong parentName="p">{`One niggle to note 👉`}</strong>{` DynamoDB considers `}<inlineCode parentName="p">{`name`}</inlineCode>{` and `}<inlineCode parentName="p">{`id`}</inlineCode>{` reserved attributes. We remap them to `}<inlineCode parentName="p">{`itemName`}</inlineCode>{` and `}<inlineCode parentName="p">{`itemId`}</inlineCode>{` when saving and have to map them back when reading.`}</p>
    <p>{`This is what I meant by `}<em parentName="p">{`"You don't want to expose raw database details to your API"`}</em>{` :)`}</p>
    <h4 {...{
      "id": "updateitem"
    }}>{`updateItem()`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`updateItem()`}</inlineCode>{` mutation is our upsert.`}</p>
    <p>{`It creates a new item when you don't send an `}<inlineCode parentName="p">{`id`}</inlineCode>{` and updates the existing item when you do. If no item is found, the mutation throws an error.`}</p>
    <p>{`And it handles `}<inlineCode parentName="p">{`createdAt`}</inlineCode>{` and `}<inlineCode parentName="p">{`updatedAt`}</inlineCode>{` timestamps.`}</p>
    <pre><code parentName="pre" {...{}}>{`// src/mutations.ts

type ItemArgs = {
  id: string
  name: string
  body: string
}

// upsert an item
// item(name, ...) or item(id, name, ...)
export const updateItem = async (_: any, args: ItemArgs) => {
  let itemId = args.id ? args.id : uuidv4()

  let createdAt = new Date().toISOString()

  // find item if exists
  if (args.id) {
    const find = await db.getItem({
      TableName: process.env.ITEM_TABLE!,
      Key: { itemId },
    })

    if (find.Item) {
      // save createdAt so we don't overwrite on update
      createdAt = find.Item.createdAt
    } else {
      throw "Item not found"
    }
  }

  const updateValues = {
    itemName: args.name,
    body: args.body,
  }

  const item = await db.updateItem({
    TableName: process.env.ITEM_TABLE!,
    Key: { itemId },
    UpdateExpression: \`SET \${db.buildExpression(
      updateValues
    )}, createdAt = :createdAt, updatedAt = :updatedAt\`,
    ExpressionAttributeValues: {
      ...db.buildAttributes(updateValues),
      ":createdAt": createdAt,
      ":updatedAt": new Date().toISOString(),
    },
    ReturnValues: "ALL_NEW",
  })

  return remapProps(item.Attributes)
}
`}</code></pre>
    <p>{`We take the id from arguments or create a new one with `}<inlineCode parentName="p">{`uuidv4()`}</inlineCode>{`.`}</p>
    <p>{`Then we try to find the item. `}</p>
    <p>{`If found, we change `}<inlineCode parentName="p">{`createdAt`}</inlineCode>{` to the existing value. Otherwise we throw an error. That helps you avoid creating new items by accident because DynamoDB always upserts.`}</p>
    <p>{`We define the `}<inlineCode parentName="p">{`updateValues`}</inlineCode>{` and build a DynamoDB UPDATE query. This can look gnarly no matter what DB you use.`}</p>
    <p>{`In the end, we return the object our database returned and let GraphQL handle the rest.`}</p>
    <h4 {...{
      "id": "deleteitem"
    }}>{`deleteItem()`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`deleteItem()`}</inlineCode>{` mutation is simple. GraphQL and the database do the work for us.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/mutations.ts

export const deleteItem = async (_: any, args: { id: string }) => {
  // DynamoDB handles deleting already deleted objects, no error :)
  const item = await db.deleteItem({
    TableName: process.env.ITEM_TABLE!,
    Key: {
      itemId: args.id,
    },
    ReturnValues: "ALL_OLD",
  })

  return remapProps(item.Attributes)
}
`}</code></pre>
    <p>{`We take the `}<inlineCode parentName="p">{`id`}</inlineCode>{` from mutation arguments, ask our database to delete the row, and return the old attributes.`}</p>
    <h3 {...{
      "id": "add-resolvers-to-the-graphql-server"
    }}>{`add resolvers to the GraphQL server`}</h3>
    <p>{`We built the resolvers, now we add them to `}<inlineCode parentName="p">{`graphql.ts`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/graphql.ts

import { item } from "./queries"
import { updateItem, deleteItem } from "./mutations"

// ...

const resolvers = {
  Query: {
    item,
  },
  Mutation: {
    updateItem,
    deleteItem,
  },
}
`}</code></pre>
    <p>{`This tells Apollo how to map queries and mutations to resolvers. We can use generic names because this is a small project.`}</p>
    <p>{`You'll want to namespace in bigger apps.`}</p>
    <h3 {...{
      "id": "try-it-out"
    }}>{`Try it out`}</h3>
    <p>{`Run `}<inlineCode parentName="p">{`yarn deploy`}</inlineCode>{` and you get a GraphQL server. There's even an Apollo playground that helps you test.`}</p>
    <iframe src="https://yrqqg5l31m.execute-api.us-east-1.amazonaws.com/dev/graphql" style={{
      width: "120%",
      border: 0,
      height: "600px"
    }}></iframe>
    <p>{`Make sure you change the URL inside the playground to: `}<inlineCode parentName="p">{`https://yrqqg5l31m.execute-api.us-east-1.amazonaws.com/dev/graphql`}</inlineCode>{`. Apollo drops the `}<inlineCode parentName="p">{`/dev/`}</inlineCode>{` part.`}</p>
    <h2 {...{
      "id": "graphql-no-code-services"
    }}>{`GraphQL no-code services`}</h2>
    <p>{`Wiring up GraphQL can get tedious with lots of repetitive code.`}</p>
    <p>{`Various no-code and low-code solutions exist that do the work for you. Look at your database and magic a GraphQL server into existence.`}</p>
    <p>{`They tend to exhibit the `}<em parentName="p">{`expose entire DB as API`}</em>{` problem. But they can be a good starting point.`}</p>
    <p>{`Next chapter we look at building lambda pipelines for distributed data processing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      